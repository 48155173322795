import React, { FC } from 'react';
import { useTheme } from '@mui/material';

interface BrandProps {
  width?: number;
  color?: string;
}

export const Brand: FC<BrandProps> = ({ width, color }) => {
  const theme = useTheme();
  return (
    <svg
      width={width || theme.spacing(19)}
      fill={color || 'none'}
      viewBox='0 0 154 17'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.1461 16.6023V4.06583L9.16666 16.6023H7.92181L2.8858 4.06583V16.6023H0V0.338776H4.01748L8.48765 11.52L12.9012 0.338776H16.9187V16.6023H14.1461Z'
        fill='white'
      />
      <path
        d='M43.8525 16.6023L42.6642 13.44H35.1951L34.0068 16.6023H30.7815L37.1755 0.338776H40.7403L47.1343 16.6023H43.8525ZM38.9296 3.16231L35.9873 10.8988H41.872L38.9296 3.16231Z'
        fill='white'
      />
      <path
        d='M72.2585 16.6023L71.0702 13.44H63.6011L62.4128 16.6023H59.1875L65.5815 0.338776H69.1463L75.4838 16.6023H72.2585ZM67.3356 3.16231L64.3932 10.8988H70.278L67.3356 3.16231Z'
        fill='white'
      />
      <path
        d='M87.9321 14.287L89.5731 12.0847C90.6482 13.2706 92.4589 14.3435 94.7222 14.3435C97.0422 14.3435 97.9476 13.2141 97.9476 12.0847C97.9476 8.69646 88.4414 10.7859 88.4414 4.79999C88.4414 2.0894 90.7613 -1.52588e-05 94.3827 -1.52588e-05C96.8724 -1.52588e-05 98.9661 0.847044 100.494 2.31528L98.8529 4.46116C97.5515 3.16234 95.7973 2.59763 94.0998 2.59763C92.4589 2.59763 91.3838 3.44469 91.3838 4.63057C91.3838 7.67998 100.89 5.81646 100.89 11.8588C100.89 14.5694 98.9661 16.8847 94.6091 16.8847C91.5535 16.8847 89.3467 15.8117 87.9321 14.287Z'
        fill='white'
      />
      <path
        d='M119.054 16.6023V2.82348H114.131V0.338776H126.863V2.82348H121.94V16.6023H119.054Z'
        fill='white'
      />
      <path
        d='M150.514 16.6023L146.949 10.5035H144.064V16.6023H141.178V0.338776H148.364C151.589 0.338776 153.739 2.42819 153.739 5.42113C153.739 8.30113 151.816 9.88231 149.835 10.2211L153.853 16.6023H150.514ZM150.797 5.42113C150.797 3.83995 149.609 2.87995 148.024 2.87995H144.064V8.07525H148.024C149.609 8.01878 150.797 7.00231 150.797 5.42113Z'
        fill='white'
      />
    </svg>
  );
};
