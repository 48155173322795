import { FC, useCallback, useState } from 'react';
import {
  IconButton,
  Popover,
  Tab,
  Tabs,
  Fade,
  Badge,
  Box,
  CircularProgress,
  AppBar
} from '@mui/material';
import { NotificationsNoneOutlined } from '@mui/icons-material';
import { RevisionProgressList } from 'track/components/RevisionProgressList';
import { NotificationList } from './NotificationList';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useMeQuery } from 'user/queries/me';
import { useRevisionUploadStore } from 'project/state/revisionUpload';

const PROCESSING = 'processing';
const ACTIVITY = 'activity';

export const NotificationsPopover: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState(ACTIVITY);
  const [buttonRef, setButtonRef] = useState<null | HTMLElement>(null);
  const hasUploads = useRevisionUploadStore(
    useCallback(
      ({ revisions }) =>
        Object.values(revisions).filter(({ upload }) => !!upload).length,
      []
    )
  );
  const { data } = useMeQuery({ notifyOnNetworkStatusChange: true });

  const hasProcessing = useMemo(
    () => !!data?.me?.pendingRevisions?.results?.length || hasUploads,
    [hasUploads, data?.me?.pendingRevisions]
  );

  const badgeContent = useMemo(() => {
    if (hasProcessing) {
      return <CircularProgress size={10} />;
    }
    return data?.me?.notifications?.unseenCount || null;
  }, [hasProcessing, data, data?.me?.notifications?.unseenCount]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setValue(hasProcessing ? PROCESSING : ACTIVITY);
  }, [hasProcessing, hasUploads, buttonRef]);

  useEffect(() => {
    if (hasUploads && buttonRef) {
      setAnchorEl(buttonRef);
    }
  }, [buttonRef, hasUploads]);

  return (
    <>
      <IconButton
        size={'small'}
        aria-controls='notification-menu'
        aria-haspopup='true'
        ref={setButtonRef}
        onClick={handleClick}
      >
        <Badge color={'primary'} badgeContent={badgeContent}>
          <NotificationsNoneOutlined fontSize={'large'} />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        elevation={5}
        open={!!anchorEl}
        transitionDuration={'auto'}
        TransitionComponent={Fade}
        onClose={handleClose}
      >
        <AppBar
          position={'static'}
          color={'default'}
          component={'div'}
          elevation={0}
        >
          <Box width={'100%'}>
            <Tabs value={value} onChange={handleChange} variant={'fullWidth'}>
              <Tab
                label={`Recent Activity ${
                  data?.me?.notifications.unseenCount
                    ? `(${data?.me?.notifications.unseenCount})`
                    : ''
                }`}
                value={ACTIVITY}
              />
              <Tab
                label={`Processing`}
                value={PROCESSING}
                disabled={!hasProcessing}
              />
            </Tabs>
          </Box>
        </AppBar>
        <Box width={420} height={500} overflow={'scroll'} maxWidth={'90vw'}>
          {value === ACTIVITY ? (
            <NotificationList onClose={handleClose} open={!!anchorEl} />
          ) : null}
          {value === PROCESSING ? (
            <RevisionProgressList onClose={handleClose} />
          ) : null}
        </Box>
      </Popover>
    </>
  );
};
