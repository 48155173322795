import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkAsSeenMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['UUID']> | Types.Scalars['UUID'];
}>;


export type MarkAsSeenMutation = { __typename?: 'Mutation', markAsSeen: { __typename?: 'NotificationBoolean', status: boolean } };


export const MarkAsSeenDocument = gql`
    mutation markAsSeen($ids: [UUID!]!) {
  markAsSeen(ids: $ids) {
    status
  }
}
    `;
export type MarkAsSeenMutationFn = Apollo.MutationFunction<MarkAsSeenMutation, MarkAsSeenMutationVariables>;

/**
 * __useMarkAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsSeenMutation, { data, loading, error }] = useMarkAsSeenMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<MarkAsSeenMutation, MarkAsSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAsSeenMutation, MarkAsSeenMutationVariables>(MarkAsSeenDocument, options);
      }
export type MarkAsSeenMutationHookResult = ReturnType<typeof useMarkAsSeenMutation>;
export type MarkAsSeenMutationResult = Apollo.MutationResult<MarkAsSeenMutation>;
export type MarkAsSeenMutationOptions = Apollo.BaseMutationOptions<MarkAsSeenMutation, MarkAsSeenMutationVariables>;