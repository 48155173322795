import { Button, List, ListItem, Typography } from '@mui/material';
import { Box, LinearProgress } from '@mui/material';
import { Link } from 'common/components/Link';
import { REVISION_STATUS_TEXT, REVISION_STATUS_VALUE } from 'track/constants';
import { useMeQuery } from 'user/queries/me';
import { trackDetailRoute } from 'track/routes';
import { FC, useCallback, useEffect } from 'react';
import { useGetRevisionBasicQuery } from 'track/queries/getRevisionBasic';
import {
  RevisionUpdatedDocument,
  RevisionUpdatedSubscription
} from 'track/subscriptions/revisionUpdated';
import { useUploadsStore } from 'common/state/uploads';
import { useRevisionUploadStore } from 'project/state/revisionUpload';

interface RevisionProgressListProps {
  onClose?: () => void;
}

export const RevisionProgressList: FC<RevisionProgressListProps> = ({
  onClose
}) => {
  const { uploads } = useUploadsStore();
  const revisions = useRevisionUploadStore(
    useCallback(
      ({ revisions }) =>
        Object.values(revisions)?.filter(({ upload }) => !!upload),
      []
    )
  );
  const { data, fetchMore } = useMeQuery();

  const loadMore = useCallback(() => {
    if (!data?.me?.pendingRevisions?.next) {
      return;
    }
    fetchMore({
      variables: {
        revisionPage: data?.me?.pendingRevisions?.next
      }
    });
  }, [data, fetchMore]);

  return (
    <List>
      {revisions.map(({ upload, file, name, track }) => {
        if (!upload) {
          return null;
        }
        return (
          <ListItem key={upload} divider>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              height={64}
              paddingTop={1}
              paddingBottom={1}
              width={'100%'}
            >
              <Typography
                sx={{
                  display: 'block',
                  width: '100%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
                variant={'body2'}
              >
                "{track?.title}"
              </Typography>
              <Typography
                sx={{
                  display: 'block',
                  width: '100%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
                variant={'body1'}
                color={'textSecondary'}
              >
                {name ? name : file?.name}
              </Typography>
              <LinearProgress
                color={'inherit'}
                variant={'determinate'}
                value={uploads?.[upload]?.progress || 0}
              />
            </Box>
          </ListItem>
        );
      })}
      {data?.me?.pendingRevisions?.results?.map((revision) => {
        return (
          <RevisionProgressPendingListItem
            id={revision.id}
            key={revision.id}
            onClose={onClose}
          />
        );
      })}

      <Box
        display={'flex'}
        marginTop={2}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {data?.me?.pendingRevisions?.next && (
          <Button size={'small'} onClick={loadMore}>
            Load More
          </Button>
        )}
      </Box>
    </List>
  );
};

const RevisionProgressPendingListItem: FC<
  { id: string } & RevisionProgressListProps
> = ({ id, onClose }) => {
  const { data, subscribeToMore } = useGetRevisionBasicQuery({
    variables: { id }
  });

  useEffect(() => {
    if (!subscribeToMore) {
      return;
    }
    subscribeToMore<RevisionUpdatedSubscription>({
      document: RevisionUpdatedDocument,
      variables: { id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const updatedTrack = subscriptionData.data.revisionUpdated;
        return Object.assign({}, prev, {
          revision: {
            ...prev.revision,
            ...updatedTrack
          }
        });
      }
    });
  }, [subscribeToMore]);

  if (!data?.revision) {
    return null;
  }
  const revision = data.revision;
  const label = REVISION_STATUS_TEXT?.[revision.status];
  const value = REVISION_STATUS_VALUE?.[revision.status];
  return (
    <Link
      key={revision.id}
      underline={'none'}
      onClick={onClose ? onClose : undefined}
      href={trackDetailRoute(revision.track.id, revision.id)}
      color={'white'}
    >
      <ListItem divider button>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          height={64}
          paddingTop={1}
          paddingBottom={1}
          width={'100%'}
        >
          <Typography
            sx={{
              display: 'block',
              width: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
            variant={'body2'}
          >
            {`"${revision.track.title}" Revision ${
              revision.name ? revision.name : `#${revision.index}`
            }`}
          </Typography>
          <Typography
            sx={{
              display: 'block',
              width: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
            variant={'body1'}
            color={'textSecondary'}
          >
            {label}
          </Typography>
          <LinearProgress
            color={'inherit'}
            variant={'determinate'}
            value={value || 0}
          />
        </Box>
      </ListItem>
    </Link>
  );
};
