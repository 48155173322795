import { useEffect, useRef } from "react";

export const useEventListener = (eventName: string, handler: (evt: Event) => void, element?: HTMLElement | Window) => {
  const savedHandler = useRef<typeof handler | null>(null);
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      element = element || window;
      const isSupported = element && element.addEventListener;
      if (!isSupported) {
        return;
      }
      const eventListener = (event: Event) => {
        if (!savedHandler.current) {
          return
        }
        savedHandler?.current(event);
      }

      element.addEventListener(eventName, eventListener);
      return () => {
        if (!element) {
          return;
        }
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element]
  );
}