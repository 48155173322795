import {
  Button,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  List,
  Hidden,
  Popover,
  Box,
  Typography,
  Fade,
  useTheme
} from '@mui/material';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { adminRoute } from 'admin/routes';
import { useLogoutMutation } from 'authentication/mutations/logout';
import { loginRoute } from 'authentication/routes';
import { Link } from 'common/components/Link';
import { useRouter } from 'next/dist/client/router';
import { pricingRoute } from 'payment/routes';
import { FC, useCallback, useState } from 'react';
import { useMeQuery } from 'user/queries/me';
import { UserAvatar } from './UserAvatar';
import { UserLockup } from './UserLockup';
import { helpRoute } from 'common/routes';

export const ProfilePopover: FC = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [logoutMutation] = useLogoutMutation();
  const { data } = useMeQuery();
  const router = useRouter();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = useCallback(async () => {
    setAnchorEl(null);
    await logoutMutation();
    router.push(loginRoute());
  }, [router]);

  if (!data?.me) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          marginLeft: theme.spacing(2)
        }}
        onClick={handleClick}
      >
        <IconButton
          size={'small'}
          aria-controls='user-menu'
          aria-haspopup='true'
        >
          <UserAvatar id={data!.me.id} />
        </IconButton>

        <Hidden lgDown>
          <Box display={'flex'} alignItems={'center'} marginLeft={2}>
            <Typography>{`${data!.me.firstName} ${
              data!.me.lastName
            }`}</Typography>
            <Box marginLeft={2}>
              <KeyboardArrowDownOutlined color={'disabled'} />
            </Box>
          </Box>
        </Hidden>
      </Box>
      <Popover
        id='user-menu'
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        keepMounted
        TransitionComponent={Fade}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <Box padding={2}>
          <UserLockup id={data.me?.id} />
          <Box marginTop={2} marginBottom={2} marginLeft={6}>
            <Link
              onClick={handleClose}
              color={'textPrimary'}
              underline={'none'}
              href={'/profile'}
            >
              <Button variant={'outlined'} size={'small'}>
                View profile
              </Button>
            </Link>
          </Box>
          <Divider />
        </Box>
        <List>
          {data?.me?.isAdministrator && (
            <Link color={'inherit'} href={adminRoute()} underline={'none'}>
              <ListItem button onClick={handleClose}>
                <ListItemText primary={'Administrator Tools'} />
              </ListItem>
            </Link>
          )}
          <Link color={'inherit'} href={helpRoute()} underline={'none'}>
            <ListItem button onClick={handleClose}>
              <ListItemText primary={'Help'} />
            </ListItem>
          </Link>
          <Link color={'inherit'} href={pricingRoute()} underline={'none'}>
            <ListItem button onClick={handleClose}>
              <ListItemText primary={'Upgrade account'} />
            </ListItem>
          </Link>
          <ListItem button onClick={logout}>
            <ListItemText primary={'Logout'} />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};
