import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  ContainerProps,
  Hidden,
  IconButton,
  Snackbar,
  Toolbar,
  Link as MuiLink,
  Backdrop
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { BaseLayout } from 'common/components/BaseLayout';
import { HorizontalLogo } from 'common/components/HorizontalLogo';
import { Link } from 'common/components/Link';
import { Brand } from 'common/components/Brand';
import { useRouter } from 'next/dist/client/router';
import { loginRoute } from 'authentication/routes';
import { MotionBox } from 'common/components/MotionBox';
import { projectListRoute } from 'project/routes';
import { ProfilePopover } from 'user/components/ProfilePopover';
import { NotificationsPopover } from 'user/components/NotificationsPopover';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useMeQuery } from 'user/queries/me';
import { useEventListener } from 'common/hooks/useEventListener';
import { cookieRoute, privacyRoute, termsRoute } from 'common/routes';
import { USER_SIGNUP_TERMS_ACKNOWLEDGEMENT } from 'user/constants';
import { useAcknowledgeMutation } from 'user/mutations/acknowledge';
import { useBreadcrumbsStore } from 'common/state/breadcrumbs';
import { useAnalytics } from 'common/hooks/useAnalytics';
import { useMeBillingLazyQuery } from 'user/queries/meBilling';

interface AuthenticatedLayoutProps extends ContainerProps {
  requireAuth?: boolean;
}

const AuthenticatedLayoutCore: FC<AuthenticatedLayoutProps> = ({
  children,
  requireAuth = true
}) => {
  const { data, previousData, loading, refetch } = useMeQuery({
    nextFetchPolicy: 'network-only'
  });
  const router = useRouter();
  const analytics = useAnalytics();
  const [acknowledge] = useAcknowledgeMutation();
  const [meBillingQuery] = useMeBillingLazyQuery();
  const { breadcrumbs } = useBreadcrumbsStore(
    useCallback(({ breadcrumbs }) => ({ breadcrumbs }), [])
  );

  useEffect(() => {
    if (!loading && !data?.me && requireAuth) {
      router.replace(loginRoute(router.asPath), undefined, { scroll: false });
    }
  }, [data?.me, loading, requireAuth]);

  useEffect(() => {
    if (!data?.me) {
      return;
    }
    
    analytics.capture('$set', {
      $set: {
        email: data.me.email
      }
    });

    if (!analytics.get_property('$user_id')) {
      analytics.identify(data.me.id);
      analytics.alias(data.me.username, data.me.id);
    }
    if (!analytics.get_property('subscription')) {
      meBillingQuery().then((value) => {
        analytics.register({
          subscription: value?.data?.me?.subscription?.product?.id
        });
      });
    }
  }, [data?.me]);

  const onFocus = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      return;
    }
    refetch();
  }, []);

  const acceptedTerms = useMemo(() => {
    if (!data?.me?.acknowledgments) {
      return true;
    }
    return [USER_SIGNUP_TERMS_ACKNOWLEDGEMENT].every(
      (expected) => data.me.acknowledgments.indexOf(expected) > -1
    );
  }, [data]);

  const acknowledgeTerms = useCallback(async () => {
    await acknowledge({
      variables: {
        key: USER_SIGNUP_TERMS_ACKNOWLEDGEMENT
      }
    });
    refetch();
  }, [data]);

  const handleBackClick = useCallback(() => {
    const route = breadcrumbs?.[breadcrumbs.length - 2].path;
    if (!route) {
      return;
    }
    router.push(route);
  }, [breadcrumbs]);

  useEventListener('visibilitychange', onFocus);

  if (!data?.me && !previousData?.me && requireAuth) {
    return null;
  }

  return (
    <>
      <AppBar position={'fixed'} color={'inherit'}>
        <Toolbar>
          <Hidden mdUp>
            {!!breadcrumbs?.length && (
              <IconButton size={'small'} onClick={handleBackClick}>
                <ChevronLeft fontSize={'large'} />
              </IconButton>
            )}
          </Hidden>
          <Link href={!!data?.me ? projectListRoute() : '/'}>
            <>
              <Hidden mdDown={true}>
                <HorizontalLogo />
              </Hidden>
              <Hidden smUp={true}>
                <Brand />
              </Hidden>
            </>
          </Link>
          <Box display={'flex'} alignItems={'center'}>
            {!!data?.me && (
              <>
                <NotificationsPopover />
                <ProfilePopover />
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <MotionBox height={'100%'} paddingTop={13}>
        <Container fixed={true}>
          <Hidden smDown>
            <Box
              aria-label='breadcrumbs'
              display={'flex'}
              alignItems={'center'}
              marginBottom={4}
            >
              {breadcrumbs?.map(({ path, text }, index) => {
                const isLast = index === breadcrumbs.length - 1;
                const variant = isLast ? 'h6' : 'body1';
                const component = path ? (
                  <Link
                    variant={variant}
                    color={'textPrimary'}
                    href={path}
                    children={text}
                  />
                ) : (
                  <Typography
                    variant={variant}
                    color={'textPrimary'}
                    children={text}
                  />
                );

                return (
                  <Fragment key={`${path}-${index}`}>
                    {component}
                    {!isLast && (
                      <ChevronRight fontSize={'large'} color={'disabled'} />
                    )}
                  </Fragment>
                );
              })}
            </Box>
          </Hidden>
          <Box overflow={'hidden'} display={'flex'}>
            {children}
          </Box>
        </Container>
      </MotionBox>
      <Backdrop
        open={!acceptedTerms}
        sx={{
          zIndex: (theme) => theme.zIndex.appBar + 1,
          '& .MuiSnackbar-root': { width: 'auto' }
        }}
      >
        <Snackbar
          open={!acceptedTerms}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          <Alert
            severity={'info'}
            action={
              <Button
                color='inherit'
                size='medium'
                variant={'outlined'}
                onClick={acknowledgeTerms}
              >
                Agree
              </Button>
            }
          >
            <>Please review our </>
            <MuiLink
              color={'textPrimary'}
              target={'_blank'}
              href={privacyRoute()}
            >
              Privacy Policy
            </MuiLink>
            <>, </>
            <MuiLink
              color={'textPrimary'}
              target={'_blank'}
              href={termsRoute()}
            >
              Terms and Conditions
            </MuiLink>
            <>, and </>
            <MuiLink
              color={'textPrimary'}
              target={'_blank'}
              href={cookieRoute()}
            >
              Cookie Policy
            </MuiLink>
            <> as they have recently changed.</>
          </Alert>
        </Snackbar>
      </Backdrop>
    </>
  );
};

export const AuthenticatedLayout: FC<AuthenticatedLayoutProps> = ({
  children,
  requireAuth = true
}) => {
  return (
    <BaseLayout>
      <AuthenticatedLayoutCore requireAuth={requireAuth}>
        {children}
      </AuthenticatedLayoutCore>
    </BaseLayout>
  );
};
