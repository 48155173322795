import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import { BaseNotificationFragmentDoc } from '../fragments/baseNotification';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotificationAddedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type NotificationAddedSubscription = { __typename?: 'Subscription', notificationAdded: { __typename?: 'Notification', id: string, type: Types.NotificationType, seen: boolean, createdAt: number, from?: { __typename?: 'User', id: string, avatar?: string | null | undefined, firstName: string, lastName: string, username: string } | null | undefined, comment?: { __typename?: 'Comment', id: string, parent?: { __typename?: 'Comment', text: string } | null | undefined } | null | undefined, revision?: { __typename?: 'Revision', id: string, name?: string | null | undefined, index: number } | null | undefined, track?: { __typename?: 'Track', id: string, title: string } | null | undefined, album?: { __typename?: 'Album', id: string, title: string } | null | undefined } };


export const NotificationAddedDocument = gql`
    subscription NotificationAdded {
  notificationAdded {
    ...BaseNotification
  }
}
    ${BaseNotificationFragmentDoc}`;

/**
 * __useNotificationAddedSubscription__
 *
 * To run a query within a React component, call `useNotificationAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationAddedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NotificationAddedSubscription, NotificationAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NotificationAddedSubscription, NotificationAddedSubscriptionVariables>(NotificationAddedDocument, options);
      }
export type NotificationAddedSubscriptionHookResult = ReturnType<typeof useNotificationAddedSubscription>;
export type NotificationAddedSubscriptionResult = Apollo.SubscriptionResult<NotificationAddedSubscription>;