import { useReactiveVar } from '@apollo/client';
import { Avatar, Button } from '@mui/material';
import { alpha } from '@mui/material';
import { Badge } from '@mui/material';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  ListItemSecondaryAction,
  useTheme
} from '@mui/material';
import { Palette, PaletteColor } from '@mui/material/styles';
import {
  AlternateEmailOutlined,
  CheckCircleOutlined,
  ChevronRight,
  CommentOutlined,
  ErrorOutline,
  HowToRegOutlined,
  LockOpen,
  LockOutlined,
  PersonAddOutlined,
  QuestionAnswerOutlined,
  ReceiptOutlined,
  ReportProblemOutlined,
  SpeakerGroupOutlined
} from '@mui/icons-material';
import { Link } from 'common/components/Link';
import { useAnalytics } from 'common/hooks/useAnalytics';
import { useDebounce } from 'common/hooks/useDebounce';
import { useOnScreen } from 'common/hooks/useOnScreen';
import { NotificationType } from 'common/types/graphql-types';
import { projectDetailRoute } from 'project/routes';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { FC } from 'react';
import { trackDetailRoute } from 'track/routes';
import { USER_NOTIFICATIONS_SEEN } from 'user/events';
import { useMarkAsSeenMutation } from 'user/mutations/markAsSeen';
import { MeQuery, useMeQuery } from 'user/queries/me';
import { markAsSeenIds } from 'user/schema/schema';
import {
  NotificationAddedDocument,
  NotificationAddedSubscription
} from 'user/subscriptions/notificationAdded';

interface NotificationListProps {
  open: boolean;
  onClose?: () => void;
}

export const NotificationList: FC<NotificationListProps> = ({
  open,
  onClose
}) => {
  const analytics = useAnalytics();
  const [notificationPage, setNotificationPage] = useState<string | null>(null);
  const { data, fetchMore, subscribeToMore } = useMeQuery({
    variables: { notificationPage }
  });
  const idsToMark = useReactiveVar(markAsSeenIds);
  const debouncedIds = useDebounce(idsToMark, 500) as string[];
  const [markAsSeen] = useMarkAsSeenMutation({
    onCompleted: () => {
      analytics.capture(USER_NOTIFICATIONS_SEEN, {
        seenNotifications: idsToMark
      });
      markAsSeenIds([]);
      fetchMore({ variables: { notificationPage } });
    }
  });
  const notifications = data?.me?.notifications?.results || [];

  useEffect(() => {
    if (!debouncedIds.length) {
      return;
    }
    markAsSeen({ variables: { ids: debouncedIds } });
  }, [debouncedIds]);

  useEffect(() => {
    if (!subscribeToMore || !data) {
      return;
    }
    subscribeToMore<NotificationAddedSubscription>({
      document: NotificationAddedDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.notificationAdded;
        return Object.assign({}, prev, {
          me: {
            ...prev.me,
            notifications: {
              ...prev.me.notifications,
              unseenCount: prev.me.notifications.unseenCount + 1,
              results: [newFeedItem, ...(prev.me.notifications?.results || [])]
            }
          }
        });
      }
    });
  }, [subscribeToMore]);

  const loadMore = useCallback(async () => {
    if (!data?.me?.notifications?.next) {
      return;
    }
    await fetchMore({
      variables: {
        notificationPage: data?.me?.notifications?.next
      }
    });
    setNotificationPage(data?.me?.notifications?.next);
  }, [data, fetchMore]);

  return !notifications.length ? (
    <Box
      height={'100%'}
      width={'100%'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography variant={'h6'}>You have no recent notifications.</Typography>
    </Box>
  ) : (
    <List>
      {notifications.map((notification) => {
        return (
          <NotificationListItem
            key={notification.id}
            {...notification}
            onClose={onClose}
            menuOpen={open}
          />
        );
      })}
      <Box
        display={'flex'}
        marginTop={2}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {data?.me?.notifications?.next && (
          <Button size={'small'} onClick={loadMore}>
            Load More
          </Button>
        )}
      </Box>
    </List>
  );
};

const NOTIFICATION_COLOR_MAP: { [key: string]: keyof Palette } = {
  [NotificationType.Comment]: 'secondary',
  [NotificationType.Reply]: 'success',
  [NotificationType.Mention]: 'info',
  [NotificationType.Invite]: 'primary',
  [NotificationType.InviteAccepted]: 'secondary',
  [NotificationType.Lock]: 'warning',
  [NotificationType.Unlock]: 'info',
  [NotificationType.NewRevision]: 'secondary',
  [NotificationType.RevisionProcessed]: 'success',
  [NotificationType.PasswordResetRequest]: 'warning',
  [NotificationType.PaymentProcessed]: 'success',
  [NotificationType.PaymentFailed]: 'error'
};
const NOTIFICATION_ICON_MAP = {
  [NotificationType.Comment]: CommentOutlined,
  [NotificationType.Reply]: QuestionAnswerOutlined,
  [NotificationType.Mention]: AlternateEmailOutlined,
  [NotificationType.Invite]: PersonAddOutlined,
  [NotificationType.InviteAccepted]: HowToRegOutlined,
  [NotificationType.Lock]: LockOutlined,
  [NotificationType.Unlock]: LockOpen,
  [NotificationType.NewRevision]: SpeakerGroupOutlined,
  [NotificationType.RevisionProcessed]: CheckCircleOutlined,
  [NotificationType.PasswordResetRequest]: ReportProblemOutlined,
  [NotificationType.PaymentProcessed]: ReceiptOutlined,
  [NotificationType.PaymentFailed]: ErrorOutline
};

type NotificationListItemProps =
  MeQuery['me']['notifications']['results'][number] & {
    menuOpen: boolean;
    onClose?: () => void;
  };

export const NotificationListItem: FC<NotificationListItemProps> = (props) => {
  const theme = useTheme();
  const Icon = NOTIFICATION_ICON_MAP[props.type];
  const palette = theme.palette[
    NOTIFICATION_COLOR_MAP[props.type]
  ] as PaletteColor;
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (
      !onScreen ||
      props.seen ||
      !props.menuOpen ||
      markAsSeenIds().indexOf(props.id) > -1
    ) {
      return;
    }
    markAsSeenIds([...markAsSeenIds(), props.id]);
  }, [onScreen, props, ref]);

  const [notificationText, link] = useMemo(() => {
    const { type } = props;

    switch (type) {
      case NotificationType.Comment: {
        if (!props.from || !props.track || !props.revision) {
          return [];
        }
        const text = `@${props.from.username} commented on track "${
          props.track.title
        } - Revision ${
          props.revision?.name
            ? props.revision?.name
            : `#${props.revision?.index}`
        }"`;

        return [
          text,
          trackDetailRoute(
            props.track!.id,
            props.revision?.id,
            props.comment?.id
          )
        ];
      }
      case NotificationType.Reply: {
        if (!props.from || !props.track || !props.revision) {
          return [];
        }
        const text = `@${
          props.from.username
        } replied to your comment on track "${props.track.title} - Revision ${
          props.revision?.name
            ? props.revision?.name
            : `#${props.revision?.index}`
        }"`;
        // const text = `@${
        //   props!.from!.username
        // } replied to your comment "${normalizeComment(
        //   props.comment!.parent!.text
        // )}"`;

        return [
          text,
          trackDetailRoute(
            props.track!.id,
            props.revision?.id,
            props.comment?.id
          )
        ];
      }
      case NotificationType.Mention: {
        if (!props.from || !props.track || !props.revision) {
          return [];
        }
        const text = `@${props.from.username} mentioned you on track "${
          props.track.title
        } - Revision ${
          props.revision?.name
            ? props.revision?.name
            : `#${props.revision?.index}`
        }"`;

        return [
          text,
          trackDetailRoute(
            props.track!.id,
            props.revision?.id,
            props.comment?.id
          )
        ];
      }
      case NotificationType.Invite: {
        if (!props.from || !props.album) {
          return [];
        }
        const text = `@${props.from.username} has invited you to the project "${props.album.title}"`;

        return [text, projectDetailRoute(props.album.id)];
      }
      case NotificationType.InviteAccepted: {
        if (!props.from || !props.album) {
          return [];
        }
        const text = `@${props.from.username} has accepted your invite to the project "${props.album.title}"`;

        return [text, projectDetailRoute(props.album.id)];
      }
      case NotificationType.Lock: {
        if (!props.track) {
          return [];
        }
        const text = `Commenting has been locked for track "${
          props.track.title
        } - Revision ${
          props.revision?.name
            ? props.revision?.name
            : `#${props.revision?.index}`
        }"`;

        return [text, trackDetailRoute(props.track.id, props.revision?.id)];
      }
      case NotificationType.Unlock: {
        if (!props.track) {
          return [];
        }
        const text = `Commenting has been unlocked for track "${
          props.track.title
        } - Revision ${
          props.revision?.name
            ? props.revision?.name
            : `#${props.revision?.index}`
        }"`;

        return [text, trackDetailRoute(props.track.id, props.revision?.id)];
      }
      case NotificationType.NewRevision: {
        if (!props.from || !props.track) {
          return [];
        }
        const text = `@${
          props.from.username
        } has created a new revision on track  "${
          props.track.title
        } - Revision ${
          props.revision?.name
            ? props.revision?.name
            : `#${props.revision?.index}`
        }"`;

        return [text, trackDetailRoute(props.track.id, props.revision?.id)];
      }
      case NotificationType.RevisionProcessed: {
        if (!props.track) {
          return [];
        }
        const text = ` "${props.track.title} - Revision ${
          props.revision?.name
            ? props.revision?.name
            : `#${props.revision?.index}`
        }" has finished processing`;

        return [text, trackDetailRoute(props.track.id, props.revision?.id)];
      }
      case NotificationType.PasswordResetRequest: {
        const text = `A password reset request has been made on your account. If you did not make this request, click here to let us know.`;

        return [text, '/report'];
      }
      case NotificationType.PaymentProcessed: {
        const text = `Your payment has processed successfully.`;

        return [text, ''];
      }
      case NotificationType.PaymentFailed: {
        const text = `Your payment has failed. Please review your payment information and try again.`;

        return [text, '/profile/billing'];
      }
      default: {
        const text = '';

        return [text, ''];
      }
    }
  }, [props]);

  const Wrapper = useMemo(() => (link ? Link : Box), [link]);

  if (!notificationText) {
    return null;
  }

  return (
    <Wrapper
      color={'inherit'}
      onClick={props.onClose ? props.onClose : undefined}
      href={link || ''}
      underline={'none'}
    >
      <ListItem divider button={!!link ? (true as false) : false} ref={setRef}>
        <ListItemAvatar>
          <Badge
            badgeContent={!props.seen ? 1 : 0}
            color='primary'
            variant='dot'
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <Avatar
              sx={{
                color: palette.main,
                backgroundColor: alpha(palette.main, 0.25)
              }}
            >
              <Icon />
            </Avatar>
          </Badge>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            sx: {
              lineClamp: 2,
              boxOrient: 'vertical',
              display: 'box',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }
          }}
          primary={notificationText}
        />
        <ListItemSecondaryAction>
          <ChevronRight fontSize={'large'} />
        </ListItemSecondaryAction>
      </ListItem>
    </Wrapper>
  );
};
